import { template as template_918d7bccc26a4deaad02e0c933d74189 } from "@ember/template-compiler";
const SidebarSectionMessage = template_918d7bccc26a4deaad02e0c933d74189(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
